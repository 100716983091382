




















  import { PRODUCTS } from '@/constant/Enums';
import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component({
    name: 'Compare',
    components: {
      compareMobil: () => import('@/components/formCompare/compareCar.vue'),
      compareHaji: () => import('@/components/formCompare/compareHaji.vue'),
      compareKesehatan: () => import('@/components/formCompare/compareHealtProtection.vue'),
      compareJiwa: () => import('@/components/formCompare/compareLifeInsurance.vue'),
      compareMotor: () => import('@/components/formCompare/compareMotorcylce.vue'),
      compareKecelakaanDiri: () => import('@/components/formCompare/comparePersonalAccident.vue'),
      compareProperti: () => import('@/components/formCompare/compareProperty.vue'),

      // listMobil: () => import('@/components/listCompare/listMobil.vue'),
      // listHaji: () => import('@/components/listCompare/listHaji.vue'),
      // listKesehatan: () => import('@/components/listCompare/listHealtProtection.vue'),
      // listJiwa: () => import('@/components/listCompare/listLifeInsurance.vue'),
      // listMotor: () => import('@/components/listCompare/listMotorcylce.vue'),
      // listKecelakaanDiri: () => import('@/components/listCompare/listPersonalAccident.vue'),
      // listProperti: () => import('@/components/listCompare/listProperty.vue'),

      CompareLayout: () => import('@/components/layouts/CompareLayout.vue'),

      protectNow: () => import('@/components/ProtectNow.vue'),
    },
    mixins: [],
  })
  export default class Compare extends Vue {

    public get availableProduct() {
      return PRODUCTS;
    }

    public created(): void {
      // on componenet created
    }

    public mounted(): void {
      // on componenet mounted
    }
  }
